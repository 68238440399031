@import '../../../../variables.scss';
.careerHero-image {
  height: 350px;
  @include sm {
    height: auto;
    width: 100%;
  }
}

.careerHero-container {
  display: flex;
  justify-content: space-around;
  margin-right: 13.5%;
  margin-left: 13.5%;
  padding-top: 100px;
  padding-bottom: 100px;
  @include sm {
    display: block;

    margin: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include tablets {
    display: block;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-right: 10%;
    margin-left: 10%;
  }
}
.careerHero-row {
  padding-right: 30px;
  padding-left: 30px;
  @include sm {
    padding: 20px;
  }
  @include tablets {
    padding: 20px;
    width: 100%;
  }
}

.careerHero-text {
  width: 100%;
  font-size: 15px;
}
