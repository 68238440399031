@import '../../variables.scss';
.bannerHomeSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-image: url(../images/banner/banner-home1.png);
  background-size: cover;
  height: 300px;

  @include sm {
    height: 200px;
    align-items: flex-start;
  }

  h1 {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 500;
    color: white;
    margin-right: 15%;
    margin-bottom: 10px;

    @include sm {
      font-size: 20px;
      padding-left: 8%;
      margin-top: 15px;
      margin-bottom: 0;
    }
    @include tablets {
      font-size: 30px;
      padding-right: 0px;
      margin-right: 5%;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-right: 15%;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 0px;
    width: 43%;
    font-size: 15px;

    @include sm {
      font-size: 15px;
      margin-right: 0;
      padding-left: 8%;

      width: 100%;
    }
    @include tablets {
      padding-right: 5%;
      width: 48%;
    }
  }
  a {
    padding: 16px 78px;
    margin-right: 15%;
    font-size: larger;
    background-color: #0072bb;
    @include sm {
      padding: 6px 10px;
      margin-left: 57%;
      margin-top: -5%;
      margin-right: 0;
      font-weight: 500;
    }
    @include tablets {
      padding: 8px 20px;
      margin-right: 5.5%;
    }
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
}

.bannerHomeBig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../images/banner/banner-home2.png);
  background-size: cover;
  height: 600px;

  @include sm {
    height: 200px;
  }
  @include tablets {
    height: 350px;
  }

  h1 {
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-left: 15%;
    padding-top: 80px;
    padding-bottom: 15px;
    @include sm {
      font-size: 20px;
      padding-top: 20px;
      padding-left: 40px;
      padding-bottom: 5px;
    }
    @include tablets {
      font-size: 30px;
      padding-top: 190px;
      padding-left: 60px;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-left: 15%;
    width: 45%;
    font-size: 15px;
    @include sm {
      padding-left: 40px;
      width: 90%;
      font-size: larger;
      font-weight: 500;
    }
    @include tablets {
      padding-left: 60px;
      width: 70%;
    }
  }
}
.bannerPolicyBig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../images/banner/banner-policy.png);
  background-size: cover;
  height: 600px;
  @include sm {
    height: 300px;
  }
  @include tablets {
    height: 350px;
  }

  h1 {
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-left: 15%;
    padding-top: 420px;
    padding-bottom: 15px;
    @include sm {
      font-size: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 70px;
      padding-bottom: 0px;
    }
    @include tablets {
      font-size: 30px;
      padding-top: 190px;
      padding-left: 60px;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-left: 15%;
    margin-right: 700px;
    font-size: 15px;

    @include sm {
      padding: 40px;
      margin-right: 30px;
      margin-top: 0px;
    }
    @include tablets {
      padding-left: 60px;
      width: 70%;
    }
  }
}

.bannerProductsSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../images/banner/banner-products.png);
  background-size: cover;
  height: 300px;

  h1 {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-left: 15%;
    margin-bottom: 10px;
    @include sm {
      font-size: 18px;
      padding-left: 9%;
      margin-top: 15px;
    }
    @include tablets {
      padding-right: 10px;
      padding-left: 60px;
      margin-top: 40px;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-left: 15%;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 50%;
    font-size: 15px;

    @include sm {
      font-size: 15px;
      padding-left: 9%;
      font-weight: 500;
      width: 95%;
    }
    @include tablets {
      padding-left: 60px;
      width: 80%;
    }
  }
}

.bannerAboutUsSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../images/banner/banner-page.png);
  background-size: cover;
  height: 300px;

  h1 {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-left: 15%;
    margin-bottom: 10px;
    @include sm {
      font-size: 22px;
      padding-right: 10px;
      padding-left: 20px;
      margin-top: 15px;
    }
    @include tablets {
      padding-right: 10px;
      padding-left: 40px;
      margin-top: 50px;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-left: 15%;
    padding-bottom: 10px;
    padding-top: 0px;
    width: 50%;
    font-size: 15px;

    @include sm {
      font-size: 15px;
      padding-left: 20px;
      margin-right: 0px;
      width: 100%;
      font-weight: 500;
    }
    @include tablets {
      padding-left: 40px;
      margin-right: 0px;
      width: 90%;
    }
  }
}

.bannerContactSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../images/banner/banner-contact.png);
  background-size: cover;
  height: 300px;
  @include sm {
    height: 250px;
  }

  h1 {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-left: 15%;
    margin-bottom: 10px;
    @include sm {
      margin-top: 15px;
      font-size: 18px;
      padding-left: 25px;
    }
    @include tablets {
      margin-top: 60px;
      padding-left: 30px;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-left: 15%;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 50%;
    font-weight: 500;
    font-size: 15px;

    @include sm {
      font-size: 14px;
      padding-left: 25px;
      margin-right: auto;
      width: 100%;
    }
    @include tablets {
      padding-left: 30px;
      margin-right: 30px;
      width: 80%;
    }
  }
}

.bannerReferenceSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-image: url(../images/banner/banner-reference.png);
  background-size: cover;
  height: 300px;

  @include sm {
    height: 250px;
    margin-bottom: 25px;
    align-items: flex-start;
  }
  @include tablets {
  }
  h1 {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-right: 15%;
    margin-bottom: 10px;
    @include sm {
      padding-left: 10%;
      font-size: 20px;
    }
    @include tablets {
      padding-right: 10%;
    }
  }
  p {
    color: white;
    padding-right: 15%;
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: 500;
    width: 43%;
    font-size: 15px;

    @include sm {
      width: 100%;

      padding-left: 10%;
    }
    @include tablets {
      padding-right: 10%;
      width: 71%;
    }
  }
}

.bannerGallerySmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../images/banner/banner-contact.png);
  background-size: cover;
  height: 300px;
  @include sm {
    height: 250px;
  }

  h1 {
    margin-top: 60px;
    font-size: 38px;
    font-weight: 500;
    color: white;
    padding-left: 15%;
    margin-bottom: 10px;
    @include sm {
      margin-top: 30px;
      font-size: 18px;
      padding-left: 25px;
    }
    @include tablets {
      margin-top: 60px;
      padding-left: 30px;
    }
  }
  p {
    color: white;
    font-weight: 500;
    padding-left: 15%;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 50%;
    font-weight: 500;
    font-size: 15px;

    @include sm {
      font-size: 15px;
      padding-left: 25px;
      margin-right: auto;
      width: 100%;
    }
    @include tablets {
      padding-left: 30px;
      margin-right: 30px;
      width: 80%;
    }
  }
}
