@import '../../../variables.scss';
.fw-img {
  height: 400px;
  object-fit: contain;
  margin: auto;
  @include sm {
    height: 300px;
    margin: 0;
    width: 100%;
  }
  @include tablets {
    height: 360px;
  }
}
.form-container {
  display: flex;
  padding-top: 100px;
  padding-bottom: 50px;
  margin-left: 15%;
  margin-right: 15%;
  @include sm {
    display: block;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: auto;
  }
  @include tablets {
    display: block;
    padding-top: 30px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 0px;
    margin: 50px;
  }
}

.form-row {
  flex: 50%;
  margin: auto;
  @include tablets {
    padding-bottom: 30px;
    flex: 0;
  }
}
