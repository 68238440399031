@import '../../../../variables.scss';
.careerForm-wrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 15%;
  margin-right: 15%;
  @include sm {
    display: block;
    margin: 15px;
  }
  @include tablets {
    display: block;
    margin: 25px;
  }
}

.careerForm-heading {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.5;
  color: #050713;
  padding-bottom: 30px;
  margin-top: 20px;
  text-align: center;
}

.careerForm-title {
  font-size: 22px;
  line-height: 1.73;
  color: #404246;
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
}

.careerForm-container {
  margin-left: 5%;
  margin-right: 5%;
}

.careerForm-text {
  font-size: 15px;
  line-height: 1.73;
  color: #404246;
  margin: 0;
  margin-bottom: 8px;
}

.careerForm-experience {
  display: flex;
  flex-direction: row;
  label {
    margin-right: 20px;
    margin-left: 25px;
  }
  input {
    margin: 5px;
  }
}
.careerForm-experience1 {
  display: flex;
  flex-direction: row;
  label {
    margin-right: 20px;
    margin-left: 25px;
    &:first-of-type {
      margin-top: 8px;
    }
    &:nth-of-type(2n) {
      margin-top: 8px;
    }
  }
  input {
    margin: 5px;
  }
}

textarea {
  resize: none;
}

.message {
  font-size: 25px;
  font-weight: 300;
}
