@import '../../../../variables.scss';
.cs-image {
  height: 250px;
  padding-bottom: 50px;
  @include sm {
    height: 150px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  @include tablets {
    height: 200px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.cs-container {
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: 15%;
  margin-right: 15%;
  border-bottom: 1px solid #0072bb;
  @include sm {
    display: block;
    padding-bottom: 30px;
    margin: 0;
    padding-top: 30px;
  }
  @include tablets {
    margin: 3%;
  }
}

.cs-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: #0072bb;
  padding-bottom: 30px;
  width: 80%;
  @include sm {
    width: 100%;
  }
  @include tablets {
    width: 100%;
  }
}

.cs-list {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  width: 80%;
  @include sm {
    width: 100%;
  }
  @include tablets {
    width: 100%;
  }
}
.cs-text {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  width: 80%;
  @include sm {
    width: 100%;
  }
  @include tablets {
    width: 100%;
  }
}
.cs-row {
  flex: 50%;
  // margin-left: 15%;
  // margin-right: 15%;
  @include sm {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include tablets {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.cs-text2 {
  font-weight: bold;
}
