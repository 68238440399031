@import '../../variables.scss';

.gallery-wrapper {
  margin-top: 80px;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-evenly;
  button {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #c0c0c0;
    border-color: #c0c0c0;
  }
  @include sm {
    margin: 35px;
    margin-top: 30px;
    display: block;
    padding: 40px;
    text-align: center;
  }
  @include tablets {
    margin: 0;
    margin-top: 80px;
  }
}

.product-container {
  margin-right: 12%;
  margin-left: 12%;
  text-align: center;

  @include sm {
    text-align: center;
    margin: 5%;
  }
}
.product-images {
  object-fit: cover;
  padding: 3%;
}
