@import '../../variables.scss';
.footer-container {
  min-height: 120px;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  background-color: #0072bb;
  color: white;
}

.logo {
  display: flex;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  p {
    padding: 1rem;
    text-decoration: none;
    color: white;
  }
  @include sm {
    font-size: 20px;
  }
}
.logo:hover {
  text-decoration: none;
}

.footer-image {
  width: 50px;
  height: 50px;
}

.footer-nav-links {
  display: flex;
  justify-content: space-between;
  width: 27%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  text-decoration: none;
  color: white;
  @include tablets {
    width: 50%;
  }
}

.footer-nav-links a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: white;
  @include sm {
    display: none;
  }
  @include tablets {
    display: none;
  }
}

.footer-div {
  display: flex;
  justify-content: space-around;
  border-bottom: 0.5px solid white;
  padding-top: 1.5rem;
  padding-bottom: 0rem;
  height: auto;
  @include sm {
    margin: 60px;
    margin-bottom: 8px;
  }
}

.footer-div-second {
  display: flex;
  justify-content: space-around;
  width: 80%;
  align-items: flex-end;
  padding: 1.25rem;
  margin: auto;
  @include sm {
    margin-top: 10px;
    display: block;
    padding: 2rem;
  }
  @include tablets {
    padding: 2rem;
    width: 90%;
  }
}

.footer-div-second a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: white;
}

.footer-div-second p {
  font-size: 16px;
  font-weight: 500;
}
.footer-icons {
  margin-right: 10px;
  @include tablets {
    margin: 5px;
    padding: 3px;
  }
}
