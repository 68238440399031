@import '../../variables.scss';
.lang-optionItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    width: 15px;
    object-fit: contain;
  }
  h5 {
    font-size: 14px;
    padding-top: 9px;
    padding-left: 5px;
    color: white;
  }

  @include sm {
    h5 {
      font-size: 18px;
    }
    img {
      width: 19px;
    }
  }
  @include tablets {
    padding-left: 10px;
  }
}

.lang-select {
  color: white;
  div {
    border: none;
    background: #0064c8;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
    @include sm {
      background: #0072bb;
    }
    @include tablets {
      background: #0072bb;
    }
  }
}
