$screen-sm-max: 600px;
$screen-md-min: 767px;
$desktop-width: 1024px;

// phones
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// tablets
@mixin tablets {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

// desktop
@mixin tabletsLandscape {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
