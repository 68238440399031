@import '../../../variables.scss';
.form {
  height: 400px;
  width: 500px;
  border-radius: 5px;
  border: 1px solid #ccc;
  @include sm {
    height: auto;
    width: 100%;
    margin: 12px;
  }
  @include tablets {
    height: auto;
    width: 100%;
  }
}

.form-heading {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.5;
  color: #050713;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-left: 12px;
}
.form-title {
  font-size: 15px;
  line-height: 1.73;
  color: #404246;
  margin: 0;
  margin-left: 12px;
}

.form-input {
  margin-left: 12px;
  margin-top: 6px;
  margin-bottom: 10px;
  width: 94%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  @include sm {
    width: 93%;
  }
}
.form-textarea {
  margin-left: 12px;
  margin-top: 6px;
  width: 94%;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-left: 10px;
  @include sm {
    width: 93%;
  }
}
.button-div {
  display: flex;
  margin-top: 35px;
  margin-left: 12px;
  margin-right: 18px;
  justify-content: space-between;
  @include sm {
    margin: 10px;
  }
  button {
    padding-bottom: 5px;
  }
  @include tablets {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.contactSuccess-message {
  margin: 0;
  padding-top: 5px;
  color: #32cd32;
}
