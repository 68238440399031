@import '../../../../variables.scss';
.mission-image {
  width: 70%;
  height: 800px;
  @include sm {
    width: 90%;
    height: 250px;
  }
  @include tablets {
    width: 85%;
    height: 450px;
  }
}
.img-container {
  display: flex;
  justify-content: center;
  padding-top: 80px;
}
.mission {
  display: flex;
  justify-content: center;

  @include sm {
    display: block;
  }
  @include tablets {
    display: block;
  }
}

.mission-cont {
  width: 20.85%;
  margin: 35px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

  @include sm {
    width: 90%;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
  }
  @include tablets {
    width: 85%;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
  }
}

.mission-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
}

.mission-paragraph {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
}

.break-line2 {
  border: 1px solid #004dff;
  width: 60px;
  margin-top: 8px;
  margin-bottom: 12px;
}
