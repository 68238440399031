@import '../../variables.scss';
.client-content {
  text-align: center;
}

.client-slider {
  height: 300px;
  display: flex;
  @include sm {
    height: 250px;
  }
}

.slider-card {
  width: 300px;
  height: 200px;
  margin: auto;

  @include sm {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.slider-wrapper {
  padding-bottom: 0;
  padding-top: 15%;
  padding-right: 15%;
  padding-left: 15%;
  @include sm {
    padding-bottom: 20px;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 5px;
  }
  @include tablets {
    padding-top: 80px;
  }
}

.client-image {
  height: auto;
  width: 250px;
  margin: 5px;
  object-fit: contain;
  @include sm {
    height: 150px;
    width: 250px;
    padding: 10px;
  }
}
.client-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
  padding-bottom: 12px;
  padding-top: 30px;
  @include sm {
    padding-bottom: 0px;
  }
}

.client-text {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  margin: 0;
  @include sm {
    margin: 40px;
    margin-bottom: 10px;
  }
}
.client-text2 {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  padding-bottom: 40px;
  @include sm {
    padding-bottom: 20px;
  }
}

.client-button {
  padding: 16px 78px;
  background-color: #0500cb;
  font-size: larger;
  @include sm {
    padding: 16px 48px;
  }
}
.client-button:hover {
  text-decoration: none;
  color: white;
}

.client-container {
  padding-top: 100px;
  @include sm {
    padding-top: 0px;
  }
}
