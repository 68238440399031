@import '../../../../variables.scss';
.strategy-image {
  height: 350px;
  @include sm {
    height: auto;
    width: 100%;
  }
  @include tablets {
    display: none;
  }
}

.development-container {
  display: flex;
  justify-content: center;
  width: 75%;
  margin-right: 15%;
  margin-left: 15%;
  margin-top: 5%;
  margin-bottom: 0;
  @include sm {
    display: block;
    margin: 10%;
    width: 80%;
  }
  @include tablets {
    display: block;
    margin: 8%;
    width: 80%;
  }
}

.development-row {
  flex: 50%;
}

.text-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
  padding-bottom: 12px;
  @include sm {
    padding-bottom: 10px;
    padding-top: 20px;
  }
}

.text1 {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  margin-right: 30px;
  width: 80%;
  @include sm {
    margin-right: 0px;
    margin-top: 10px;
    width: 100%;
  }
  @include tablets {
    width: 100%;
  }
}
.text2 {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  width: 80%;
  @include tablets {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
}
