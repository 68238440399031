@import '../../../../variables.scss';

.services {
  padding-top: 87px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 87px;
  @include sm {
    padding: 20px;
  }
}

.container {
  display: grid;
  grid-template-columns: auto auto;
  grid-row: auto auto;
  grid-column-gap: 35%;
  grid-row-gap: 5%;
  margin-right: 10%;
  margin-left: 14%;
  @include sm {
    display: block;
    margin: auto;
  }
  @include tablets {
    margin: 6%;
    grid-column-gap: 10%;
  }
}

.box {
  width: 150%;
  @include sm {
    width: 100%;
  }
  @include tablets {
    width: 100%;
  }
}

.title-container {
  width: 100%;
  padding-top: 15px;
}
.title {
  display: inline;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
  min-height: auto;
}
.break-line {
  border: 1px solid black;
  width: 40px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.text {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  width: 85%;
  @include sm {
    width: 100%;
  }
  @include tablets {
    width: 100%;
  }
}

.service-img {
  width: 50px;
}
