@import '../../../../variables.scss';
.imageGallery {
  text-align: center;
  margin: 6.5%;
  @include sm {
    margin: 0;
    padding-top: 30px;
  }
}

.gallery-images {
  object-fit: cover;
  padding: 3%;
  @include sm {
    padding: 0;
  }
}
