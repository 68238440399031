@import '../../../../variables.scss';

.hero-container {
  height: 90vh;
  width: 100%;
  @include tablets {
    height: 45vh;
  }
  @include sm {
    height: 40vh;
  }
}
.slider-image {
  height: 90vh;
  width: 100%;
  object-fit: cover;
  @include tablets {
    height: 45vh;
  }
  @include sm {
    height: 40vh;
  }
}
.slide-content {
  position: relative;
}

.content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px 20px 20px 15%;
  p {
    font-weight: 500;
    font-size: 16px;
  }
  @include tablets {
    padding: 20px 20px 20px 8%;
  }
  @include sm {
    padding: 20px 20px 20px 10%;
    h1 {
      font-size: 1rem;
      color: white;
    }
    p {
      font-size: 0.9rem;
    }
  }
}

.slick-dots {
  bottom: 25px;
  @include sm {
    bottom: 18px;
  }
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  @include sm {
    font-size: 10px;
  }
}
.slick-dots li.slick-active button {
  border: 4px solid #d3d3d3;
  border-radius: 100%;
  @include sm {
    border: 5px solid #d3d3d3;
  }
}
.slick-dots li.slick-active button:before {
  color: transparent;
}
