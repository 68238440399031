@import '../../../../variables.scss';
.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 150px;
  padding-top: 125px;
  padding-right: 15%;
  padding-left: 15%;
  @include sm {
    padding: 50px;
    display: block;
    text-align: center;
  }
}
.image-client {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
}
