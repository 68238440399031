@import '../../../../variables.scss';
.hs-container {
  height: 90vh;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  @include sm {
    display: block;
    height: auto;
  }
  @include tablets {
    height: 45vh;
    margin: 0;
  }
}
.hs-row {
  flex: 50%;
}
.hs-image {
  height: 90vh;
  @include sm {
    display: none;
  }
  @include tablets {
    height: 45vh;

    padding-left: 0px;
  }
}

.hs-title {
  font-size: 34px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
  padding-top: 150px;
  @include sm {
    padding-top: 30px;
    font-size: 20px;
    margin: 0px;
  }
  @include tablets {
    font-size: 20px;
    padding-top: 20px;
  }
}

.hs-text1 {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  width: 70%;
  padding-top: 30px;
  @include sm {
    margin: 0;
    width: 100%;
  }
  @include tablets {
    font-size: 12px;
    padding-top: 5px;
    width: 85%;
    text-align: justify;
  }
}
.hs-text2 {
  font-size: 15px;
  line-height: 1.73;
  color: #050713;
  width: 70%;
  @include sm {
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
  }
  @include tablets {
    font-size: 12px;
    width: 85%;
    text-align: justify;
  }
}
