@import '../../../../variables.scss';
.certification-heading {
  font-size: 34px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 50px;
}

.certification-images {
  padding: 10%;
}

.certification-wrapper {
  text-align: center;
  margin-right: 17%;
  margin-left: 17%;
  padding-bottom: 40px;
  @include sm {
    margin: 0;
    padding-top: 30px;
  }
}
