@import '../../variables.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
}

nav {
  display: flex;
  justify-content: space-around;
  min-height: 100px;
  align-items: flex-end;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  // background-color: #004dff;
  // background-color: #007cc7;
  background-color: #0064c8;
  // background-color: #006edc;
  color: white;
  text-decoration: none;
}

nav .logo {
  font-size: 25px;
  font-weight: 600;
  text-decoration: none;
  :hover {
    color: white;
  }
}

.logo-image {
  width: 75px;
  height: 75px;
}

nav .nav-links {
  display: flex;
  justify-content: space-around;
  width: 34%;
  margin-bottom: 35px;
  text-decoration: none;
  color: white;
  &:visited {
    color: red;
  }
}

nav .nav-links a {
  font-size: 16px;
  font-weight: 40;
  text-decoration: none;
  color: white;

  span {
    color: rgb(249, 249, 249);
    padding: 2px 0px;
    white-space: nowrap;
    position: relative;

    &:before {
      background-color: rgb(249, 249, 249);
      border-radius: 0px 0px 4px 4px;
      bottom: -6px;
      content: '';
      height: 1px;
      left: 0px;
      opacity: 0;
      position: absolute;
      right: 0px;
      transform-origin: left center;
      transform: scaleX(0);
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      visibility: hidden;
      width: auto;
    }
  }
  &:hover {
    span:before {
      transform: scaleX(1);
      visibility: visible;
      opacity: 1 !important;
    }
  }
}

nav .nav-links a:hover {
  cursor: pointer;
}

nav .burger {
  font-size: 28px;
  display: none;
  cursor: pointer;
  z-index: 5;
  margin-bottom: 30px;
  transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
}

@media all and (max-width: 768px) {
  nav .hamburger-react {
    display: block;
  }
  nav .nav-links {
    position: fixed;
    display: block;
    text-align: center;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #0072bb;
    // padding: 150px 20px 20px 20px;
    width: 100%;
    transform: translateX(-100%);
    transition: 0.5s ease-in-out;
    color: white;
    text-decoration: none;
    z-index: 1;
    margin-bottom: 20px;
  }
  nav .nav-links span {
    font-size: 23px;
  }

  nav .nav-links li {
    margin: 5%;
  }

  nav .nav-links a:hover {
    border-bottom: 0px solid #fff;
  }
}

.logo-text {
  margin: 10px;
}

.language-selector {
  display: block;
  width: 100px;
  margin-left: -2%;
  margin-bottom: -5%;
  margin-top: -1.3%;
  @include sm {
    width: 33%;
    margin-left: 30%;
    margin-bottom: -12px;
    padding-top: 4%;
  }
  @include tablets {
    width: 20%;
  }
}

.hamburger-react {
  z-index: 2;
  margin-bottom: 25px;
  display: none;
}

// Dark Blue: #12232E

// Lighter Blue: #007CC7

// Lightest Blue: #4DA8DA

// Shadow of Dark Blue: #203647

// Shadow of Light Blue: #EEFBFB
